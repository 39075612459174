import React from 'react';
import { Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppBar from './components/AppBar';

import { protectedRoutes } from '../../routes';

const Home = ({ user }) => {
  const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
  const filteredRoutes = protectedRoutes.filter((route) => {
    const { roles } = route;
    if (roles) {
      return userGroups.length > 0 && userGroups.some((group) => roles.includes(group));
    } else {
      return !roles;
    }
  });

  return (
    <div>
      <AppBar routes={filteredRoutes} />
      <div style={{ width: '100%', height: 'calc(100vh - 84px)', marginTop: 64, overflow: 'auto' }}>
        <Routes>
          {filteredRoutes.map((item) => (
            <item.route
              key={item.path}
              path={item.path}
              element={<item.element />}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Home;
