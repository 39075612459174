import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';

const Oops = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Alert severity="error">
        <Typography component="h1" variant="h5">Oops!</Typography>
        <p>There was a problem!</p>
      </Alert>
    </Container>
  );
};

export default Oops;
