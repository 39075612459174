import React, { Fragment, useState } from 'react';
import { useNavigate, Link as RouteLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import ControlledInput from 'components/Form/ControlledInput';

const Register = () => {
  const navigate = useNavigate();

  // form states
  const { control, handleSubmit, formState } = useForm();
  const { isSubmitting, errors } = formState;

  const [email, setEmail] = useState('');
  const [completeNewPassword, setCompleteNewPassword] = useState(false);
  const [error, setError] = useState(false);

  const forgotInputs = [{
    type: 'text',
    name: 'email',
    label: 'Email Address',
    autoFocus: true,
    required: true,
    invalidText: 'A correctly formatted email is required',
  }];

  const completeInputs = [{
    type: 'password',
    name: 'newPassword',
    label: 'New Password',
    required: true,
    invalidText: 'A new password is required',
  }, {
    type: 'text',
    name: 'code',
    label: 'Verification Code',
    autoFocus: true,
    required: true,
    invalidText: 'A verification code is required',
  }];

  async function handleForgotPassword({ email }) {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.forgotPassword(normalized);

      setEmail(email);
      setCompleteNewPassword(true);
    } catch (e) {
      console.warn(e);
    }
  }

  async function handleCompleteNewPassword({ code, newPassword }) {
    try {
      const normalized = email.trim().toLowerCase();
      await Auth.forgotPasswordSubmit(
        normalized,
        code,
        newPassword,
      );

      await Auth.signIn(normalized, newPassword);
      navigate('/');
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        setError(e.message);
      } else if (e.code === 'InvalidPasswordException') {
        setError('Passwords must be at least 8 characters in length, contain one uppercase letter, one lowercase letter and a number.');
      } else {
        setError('There was a problem resetting your password, please contact support');
      }
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs" sx={{
      mt: 5,
    }}>
      <Typography variant="h5">
        Forgot Password
      </Typography>
      {!completeNewPassword && (
        <form
          onSubmit={handleSubmit(handleForgotPassword)}
          noValidate
        >
          <Grid container spacing={2}>
            {forgotInputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            Reset
          </Button>
        </form>
      )}

      {completeNewPassword && (
        <Fragment>
          <p>Please choose a new password and provide the code we sent to your email address at:</p>
          <p><strong>{email}</strong></p>
          <form
            onSubmit={handleSubmit(handleCompleteNewPassword)}
            noValidate
          >
            <Grid container spacing={2}>
              {completeInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              Set New Password
            </Button>
          </form>
        </Fragment>
      )}
      <Grid container>
        <Grid item xs>
          <Link to="/signin" href="#" variant="body2" component={RouteLink}>
            Back to sign in
          </Link>
        </Grid>
      </Grid>
      <Snackbar
        open={error !== false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Register;
