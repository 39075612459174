import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import moment from 'moment';

import DataTable from 'components/DataTable';
import NestedTableContainer from 'components/DataTable/NestedTableContainer';
import { renderLink } from 'components/DataTable/customBodyRenders';
import awsAccounts from './aws-accounts.json';
import { useClientApi } from 'utilities/restapi';
import ClientDetails from 'pages/Clients/ClientDetails';
import { sortBy } from 'utilities/sorting';
import awsConfig from '../../aws-exports';

const { endpoint } = awsConfig.aws_cloud_logic_custom.find(({ name }) => name === 'paycrmapi');

const RolloutButton = ({ clientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useClientApi('rollout');

  const rollout = async () => {
    try {
      window.alert('Are you sure to rollout the new verison?');

      setIsLoading(true);
      const data = await mutate({ clientId });

      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="submit"
      size="small"
      variant="contained"
      color="primary"
      fullWidth
      // style={{ marginBottom: 16 }}
      disabled={!clientId || isLoading}
      onClick={rollout}
    >
      CMD
    </Button>);
};

const SetPingdomButton = ({ clientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useClientApi('setPingdom');

  const setPingdom = async () => {
    try {
      window.alert('Are you sure to set pingdom monitors for this client?');

      setIsLoading(true);
      const data = await mutate({ clientId, endpoint });

      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="submit"
      size="small"
      variant="outlined"
      color="primary"
      fullWidth
      // style={{ marginBottom: 16 }}
      disabled={!clientId || isLoading}
      onClick={setPingdom}
    >
      CMD
    </Button>);
};

const ClientTable = ({ data: inData = [] }) => {
  const [data, setData] = useState([]);
  const [latestVersion, setLatestVersion] = useState('');

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <ClientDetails clientId={id} />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        display: true,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'awsAccountId',
      label: 'AWS Account',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return awsAccounts[value] ? `${awsAccounts[value]} (${value})` : value;
        },
      },
    },
    {
      name: 'awsRegion',
      label: 'AWS Region',
      options: {
        display: false,
        sort: true,
        filter: true,
      },
    },
    {
      name: 'awsAmplifyEnvName',
      label: 'AWS Backend',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'awsAmplifyGitBranchName',
      label: 'Git Branch',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'awsAppSyncApiId',
      label: 'AWS AppSync ID',
      options: {
        display: false,
        sort: true,
        filter: false,
      },
    },
    {
      name: 'domain',
      label: 'Domain',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'url',
      label: 'UI',
      options: {
        sort: true,
        filter: false,
        customBodyRender: renderLink,
      },
    },
    {
      name: 'awsAppSyncGraphqlEndpoint',
      label: 'API',
      options: {
        sort: true,
        filter: false,
        customBodyRender: renderLink,
      },
    },

    {
      name: 'awsAppSyncGraphqlEndpointBackup',
      label: 'API Backup',
      options: {
        display: false,
        sort: true,
        filter: false,
        customBodyRender: renderLink,
      },
    },
    {
      name: 'restApiEndpoint',
      label: 'Rest API',
      options: {
        sort: false,
        filter: false,
        customBodyRender: renderLink,
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return value === latestVersion ?
            <span>{value}</span> :
            <span style={{ color: 'red' }}>{value}</span>;
        },
      },
    },
    {
      name: 'latestJob',
      label: 'Amplify Build',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (job) => {
          return <div>
            {job.status === 'FAILED' ? <div style={{ color: 'red' }}>{job.status}</div> : <div>{job.status}</div>}
            {job.endTime && <div>{moment().diff(job.endTime, 'days')} days ago</div>}
          </div>;
        },
      },
    },
    {
      name: 'latestJob.commitMessage',
      label: 'Build Message',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'awsAmplifyGitBranchEnabledAutoBuild',
      label: 'Auto build enabled',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return value ?
            <span>✅</span> :
            <span>❌</span>;
        },
      },
    },
    {
      name: 'id',
      label: 'Set Pingdom',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return <SetPingdomButton clientId={value} />;
        },
      },
    },

    {
      name: 'id',
      label: 'Roll Out',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return <RolloutButton clientId={value} />;
        },
      },
    },
  ];

  useEffect(() => {
    if (inData.length === 0) return;

    const { version } = inData.sort(sortBy('version', true))[0];
    setLatestVersion(version);

    setData(inData);
  }, [inData]);

  return (
    <DataTable
      title={'Clients'}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

ClientTable.propTypes = {
  data: PropTypes.array,
};

RolloutButton.propTypes = {
  clientId: PropTypes.string,
};

SetPingdomButton.propTypes = {
  clientId: PropTypes.string,
};

export default ClientTable;
