import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  useClientApi,
} from 'utilities/restapi';
import ClientTable from './ClientTable';

const Clients = (props) => {
  const [enabled, setEnabled] = useState(true);
  const { data } = useClientApi('list', { enabled });

  useEffect(() => {
    if (data) {
      setEnabled(false);
    }
  }, [data]);

  // const { data: clients } = useAsyncListAll(listClients, {}, { queryKey: ['clients'] });
  // const { data } = useQuery({
  //   queryKey: ['clients', 'version'],
  //   queryFn: () => {
  //     return Promise.all(clients.map(async (client) => {
  //       try {
  //         const res = await fetch(`${client.restApiEndpoint}/healthcheck?key=version`);
  //         const { status: version } = await res.json();
  //         client.version = version;
  //       } catch (e) {
  //         client.version = 'down';
  //       }
  //       return client;
  //     }));
  //   },
  //   enabled: !!clients,
  // });

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ClientTable
            data={data}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Clients;
