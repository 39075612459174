import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { publicRoutes } from '../routes';

import ProtectedRoute from '../components/ProtectedRoute';
import Home from '../pages/Home';

const App = () => {
  return (
    <Routes>
      {publicRoutes.map((item) => (
        <item.route
          key={item.path}
          path={item.path}
          element={<item.element />}
        />
      ))}
      <Route
        path="/*"
        element={
          <ProtectedRoute component={Home} />
        }
      />
    </Routes>
  );
};

export default App;
