import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ProtectedRoute = ({ component: Component, ...props }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (!user) {
        try {
          const user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (user) {
            setUser(user);
            setIsAuthenticated(true);
            localStorage.setItem('paycrm:username', user.username);
          } else {
            setIsAuthenticated(false);
          }
        } catch (e) {
          if (e === 'The user is not authenticated') {
            setIsAuthenticated(false);
          }
        }
      }
    })();
  }, [user]);

  if (isAuthenticated === false) {
    return (<Navigate to="/signin" />);
  }

  if (!user || isAuthenticated === undefined) {
    return (
      <Backdrop open={!user}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (<Component user={user} {...props} />);
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  user: PropTypes.object,
};

export default ProtectedRoute;
