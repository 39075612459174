import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ResponsiveLine } from '@nivo/line';
import { asyncListAll } from 'utilities/graph';
import { appHealthHistoriesByAppHealthIdAndCreatedAt } from './queries';

const startDate = moment().subtract(3, 'days').toISOString();
const endDate = moment().toISOString();

const AppHealthLineChart = ({ appHealthData = [] }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await Promise.all(appHealthData.map(async ({ id: appHealthId, clientId, clientName, key }) => {
        const historyData = await asyncListAll(appHealthHistoriesByAppHealthIdAndCreatedAt, {
          appHealthId,
          createdAt: {
            between: [startDate, endDate],
          },
        });

        return {
          id: `${key}`,
          data: historyData.map(({ status, responseTimestamp, createdAt }) => {
            return { x: moment(createdAt).format('YYYY-MM-DD HH'), y: responseTimestamp };
          }),
        };
      }));

      setData(data);
    })();
  }, [appHealthData]);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 200, bottom: 50, left: 50 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        // stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'date',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'sec',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

AppHealthLineChart.propTypes = {
  appHealthData: PropTypes.array,
};

export default AppHealthLineChart;
