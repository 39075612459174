import { Route } from 'react-router-dom';

import ForgotPassword from 'pages/Auth/ForgotPassword';
import Oops from 'pages/Auth/Oops';
import SignIn from 'pages/Auth/SignIn';

// import Case from 'pages/Cases/Case';
// import Board from 'pages/Cases/Board';
// import Transactions from 'pages/Transactions';
// import Emails from 'pages/Emails';
import AppHealthDashboard from 'pages/AppHealthDashboard';
import Clients from 'pages/Clients';
import ClientDetails from 'pages/Clients/ClientDetails';

// define public routes here
const unauth = [
  {
    paths: [{ path: '/forgot-password' }],
    element: ForgotPassword,
  },
  {
    paths: [{ path: '/oops' }],
    element: Oops,
  },
  {
    paths: [
      { path: '/login' },
      { path: '/login/callback' },
      { path: '/signin' },
    ],
    element: SignIn,
  },
];

// define protected routes and optional roles
const auth = [
  /* examples
  {
    title: 'Admin',
    paths: [{ path: '/admins' }],
    element: ExampleAdmin,
    roles: ['Admins'],
  },
  {
    title: 'Hidden Admin Route',
    paths: [{ path: '/hidden' }],
    element: ExampleAdmin,
    roles: ['Admin'],
    hideFromMenu: true,
  },
  {
    title: 'Staff',
    paths: [{ path: '/staffs' }],
    element: ExampleStaff,
    roles: ['Staffs'],
  },
  */
  {
    title: 'Clients',
    paths: [
      { path: '/' },
      // { path: '/clients' },
    ],
    element: Clients,
  },
  {
    title: 'Client',
    paths: [
      { path: '/client/:clientId' },
    ],
    element: ClientDetails,
    hideFromMenu: true,
  },
  {
    title: 'App Health Dashboard',
    paths: [{ path: '/appHealthDashboard' }],
    element: AppHealthDashboard,
  },
  // {
  //   title: 'Cases',
  //   paths: [{ path: '/cases' }],
  //   element: Board,
  // },
  // {
  //   title: 'Case',
  //   paths: [{ path: '/case/:id' }],
  //   element: Case,
  //   hideFromMenu: true,
  // },
  // {
  //   title: 'Transactions',
  //   paths: [{ path: '/transactions' }],
  //   element: Transactions,
  // },
  // {
  //   title: 'Emails',
  //   paths: [{ path: '/emails' }],
  //   element: Emails,
  // },
];

// normalized exports
export const publicRoutes = [
  ...unauth,
].map((item) => {
  item.route = Route;
  return item;
}).reduce((all, item) => {
  item.paths.forEach(({ path }) => {
    all.push(Object.assign({ path }, item));
  });
  return all;
}, []);

export const protectedRoutes = [
  ...auth,
].map((item) => {
  item.route = Route;
  return item;
}).reduce((all, item) => {
  item.paths.forEach(({ path }) => {
    all.push(Object.assign({ path }, item));
  });
  return all;
}, []);
