import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import AppHealthLineChart from './AppHealthLineChart';
import AppHealthTable from './AppHealthTable';

import { useAsyncListAll } from 'utilities/graph';
import { listAppHealths } from './queries';
import { listClients } from 'graphql/queries';

const AppHealthDashboard = (props) => {
  const [data, setData] = useState([]);

  const { data: appHealths } = useAsyncListAll(listAppHealths);
  const { data: clients } = useAsyncListAll(listClients);

  // Effects
  useEffect(() => {
    if (!appHealths || !clients) return;

    appHealths.forEach((item) => {
      item.clientName = (clients.find(({ id }) => id === item.clientId) || {}).name;
    });

    setData(appHealths);
  }, [appHealths, clients]);

  // No data present
  if (data.length === 0) {
    return (
      <Container maxWidth='lg'>
        <p>No app health data present.</p>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} style={{ height: 360 }}>
          <AppHealthLineChart appHealthData={data} />
        </Grid> */}
        <Grid item xs={12}>
          <AppHealthTable
            appHealthData={data}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppHealthDashboard;
