/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppHealth = /* GraphQL */ `
  query GetAppHealth($id: ID!) {
    getAppHealth(id: $id) {
      id
      clientId
      key
      isActive
      status
      statusMessage
      statusUpdatedAt
      interval
      pingdomCheckId
      appHealthHitory {
        items {
          id
          appHealthId
          status
          statusMessage
          responseTimestamp
          expirationUnixTime
          appHealth {
            id
            clientId
            key
            isActive
            status
            statusMessage
            statusUpdatedAt
            interval
            pingdomCheckId
            postmanCollectionId
            postmanEnvironmentId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          appHealthAppHealthHitoryId
        }
        nextToken
      }
      postmanCollectionId
      postmanEnvironmentId
      createdAt
      updatedAt
    }
  }
`;
export const listAppHealths = /* GraphQL */ `
  query ListAppHealths(
    $id: ID
    $filter: ModelAppHealthFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppHealths(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientId
        key
        isActive
        status
        statusMessage
        statusUpdatedAt
        interval
        pingdomCheckId
        appHealthHitory {
          items {
            id
            appHealthId
            status
            statusMessage
            responseTimestamp
            expirationUnixTime
            createdAt
            updatedAt
            appHealthAppHealthHitoryId
          }
          nextToken
        }
        postmanCollectionId
        postmanEnvironmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppHealthHistory = /* GraphQL */ `
  query GetAppHealthHistory($id: ID!) {
    getAppHealthHistory(id: $id) {
      id
      appHealthId
      status
      statusMessage
      responseTimestamp
      expirationUnixTime
      appHealth {
        id
        clientId
        key
        isActive
        status
        statusMessage
        statusUpdatedAt
        interval
        pingdomCheckId
        appHealthHitory {
          items {
            id
            appHealthId
            status
            statusMessage
            responseTimestamp
            expirationUnixTime
            createdAt
            updatedAt
            appHealthAppHealthHitoryId
          }
          nextToken
        }
        postmanCollectionId
        postmanEnvironmentId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      appHealthAppHealthHitoryId
    }
  }
`;
export const listAppHealthHistories = /* GraphQL */ `
  query ListAppHealthHistories(
    $id: ID
    $filter: ModelAppHealthHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppHealthHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        appHealthId
        status
        statusMessage
        responseTimestamp
        expirationUnixTime
        appHealth {
          id
          clientId
          key
          isActive
          status
          statusMessage
          statusUpdatedAt
          interval
          pingdomCheckId
          appHealthHitory {
            nextToken
          }
          postmanCollectionId
          postmanEnvironmentId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        appHealthAppHealthHitoryId
      }
      nextToken
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      name
      title
      category
      description
      awsEnvironment
      awsAppId
      source
      spam
      createdAt
      updatedAt
      messages {
        items {
          id
          from
          source
          body
          createdAt
          updatedAt
          case {
            id
            name
            title
            category
            description
            awsEnvironment
            awsAppId
            source
            spam
            createdAt
            updatedAt
          }
          caseMessagesId
        }
        nextToken
      }
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        category
        description
        awsEnvironment
        awsAppId
        source
        spam
        createdAt
        updatedAt
        messages {
          items {
            id
            from
            source
            body
            createdAt
            updatedAt
            caseMessagesId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      description
      awsAccountId
      awsRegion
      awsAmplifyAppId
      awsAmplifyGitBranchName
      awsAmplifyGitBranchEnabledAutoBuild
      awsAmplifyEnvName
      awsAmplifyWebhookEndpoint
      domain
      url
      awsAppSyncApiId
      awsAppSyncGraphqlEndpoint
      awsAppSyncGraphqlEndpointBackup
      restApiEndpoint
      restApiEndpointBackup
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        awsAccountId
        awsRegion
        awsAmplifyAppId
        awsAmplifyGitBranchName
        awsAmplifyGitBranchEnabledAutoBuild
        awsAmplifyEnvName
        awsAmplifyWebhookEndpoint
        domain
        url
        awsAppSyncApiId
        awsAppSyncGraphqlEndpoint
        awsAppSyncGraphqlEndpointBackup
        restApiEndpoint
        restApiEndpointBackup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmail = /* GraphQL */ `
  query GetEmail($id: ID!) {
    getEmail(id: $id) {
      id
      status
      from
      name
      subject
      body
      bodyHtml
      originalMessageId
      emailS3Key
      attachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmails = /* GraphQL */ `
  query ListEmails(
    $id: ID
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmails(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        from
        name
        subject
        body
        bodyHtml
        originalMessageId
        emailS3Key
        attachments {
          s3Key
          filename
          contentType
          size
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      from
      source
      body
      createdAt
      updatedAt
      case {
        id
        name
        title
        category
        description
        awsEnvironment
        awsAppId
        source
        spam
        createdAt
        updatedAt
        messages {
          items {
            id
            from
            source
            body
            createdAt
            updatedAt
            caseMessagesId
          }
          nextToken
        }
      }
      caseMessagesId
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from
        source
        body
        createdAt
        updatedAt
        case {
          id
          name
          title
          category
          description
          awsEnvironment
          awsAppId
          source
          spam
          createdAt
          updatedAt
          messages {
            nextToken
          }
        }
        caseMessagesId
      }
      nextToken
    }
  }
`;
export const getAppHealthByClientIdByKey = /* GraphQL */ `
  query GetAppHealthByClientIdByKey(
    $clientId: ID!
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppHealthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAppHealthByClientIdByKey(
      clientId: $clientId
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        isActive
        status
        statusMessage
        statusUpdatedAt
        interval
        pingdomCheckId
        appHealthHitory {
          items {
            id
            appHealthId
            status
            statusMessage
            responseTimestamp
            expirationUnixTime
            createdAt
            updatedAt
            appHealthAppHealthHitoryId
          }
          nextToken
        }
        postmanCollectionId
        postmanEnvironmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appHealthHistoriesByAppHealthIdAndCreatedAt = /* GraphQL */ `
  query AppHealthHistoriesByAppHealthIdAndCreatedAt(
    $appHealthId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppHealthHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appHealthHistoriesByAppHealthIdAndCreatedAt(
      appHealthId: $appHealthId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appHealthId
        status
        statusMessage
        responseTimestamp
        expirationUnixTime
        appHealth {
          id
          clientId
          key
          isActive
          status
          statusMessage
          statusUpdatedAt
          interval
          pingdomCheckId
          appHealthHitory {
            nextToken
          }
          postmanCollectionId
          postmanEnvironmentId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        appHealthAppHealthHitoryId
      }
      nextToken
    }
  }
`;
export const getEmailsByStatusByCreatedAt = /* GraphQL */ `
  query GetEmailsByStatusByCreatedAt(
    $status: EmailStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmailsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        from
        name
        subject
        body
        bodyHtml
        originalMessageId
        emailS3Key
        attachments {
          s3Key
          filename
          contentType
          size
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
