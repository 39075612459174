import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DataTable from 'components/DataTable';
import awsExports from '../../aws-exports';

const restApiEndpoint = awsExports.aws_cloud_logic_custom.find(({ name }) => name === 'paycrmapi').endpoint;

const AppHealthTable = ({ clientId, appHealthData }) => {
  const [data, setData] = useState([]);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'clientId',
      label: 'Client ID',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: {
        display: !clientId,
        sort: true,
        filter: true,
      },
    },

    {
      name: 'key',
      label: 'key',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return <code>{value}</code>;
        },
      },
    },
    {
      name: 'isActive',
      label: 'Active',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          return value ?
            <span>✅</span> :
            <span>❌</span>;
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          switch (value) {
          case 'up':
            return <span>✅</span>;
          case 'down':
            return <span>❌</span>;
          default:
            return 'noData';
          }
        },
      },
    },
    {
      name: 'statusMessage',
      label: 'Status Message',
      options: {
        display: false,
        sort: false,
        filter: false,
      },
    },
    {
      name: 'statusUpdatedAt',
      label: 'Status last updated at',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value) => {
          return moment(value).fromNow();
        },
      },
    },
    {
      name: 'interval',
      label: 'Interval',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'pingdomCheckId',
      label: 'Pingdom Check',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value) => {
          if (!value) return '';
          return <a href={`https://my.pingdom.com/app/reports/uptime#check=${value}`} target="_blank" rel="noreferrer">{value}</a>;
        },

      },
    },
  ];

  useEffect(() => {
    if (!appHealthData || appHealthData.length === 0) return;

    const data = (appHealthData).map((item) => {
      item.pingdomUrl = `${restApiEndpoint}/healthcheck?source=pingdom&clientId=${item.clientId}&key=${item.key}`;
      return item;
    });

    setData(data);
  }, [appHealthData]);

  return (
    <DataTable
      title={'App Health Data'}
      data={appHealthData || data}
      columns={columns}
    />
  );
};

AppHealthTable.propTypes = {
  clientId: PropTypes.string,
  appHealthData: PropTypes.array,
};

export default AppHealthTable;
