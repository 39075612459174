import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

const SignIn = () => {
  const navigate = useNavigate();

  // form states
  const { formState } = useForm();
  const { isSubmitting } = formState;

  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('authenticated user', user);
        if (user) {
          navigate('/cases');
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [navigate]);

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn({
        provider: 'okta',
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  function handleCloseMessage() {
    setMessage(false);
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
      <Typography variant="h5">
        Sign In
      </Typography>
      <Button
        type="button"
        size="large"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={handleSignIn}
        sx={{
          mt: 2,
          mb: 2,
        }}
      >
        Go
      </Button>
      <Snackbar
        open={message !== false}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={handleCloseMessage}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignIn;
