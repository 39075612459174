import {
  API,
  Cache,
  graphqlOperation,
} from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';

import * as retry from 'retry';

const USE_CACHE = false;

const modelCache = Cache.createInstance({
  keyPrefix: 'modelCache',
  defaultTTL: 86400000, // 1 day
});

const listCache = Cache.createInstance({
  keyPrefix: 'listCache',
  defaultTTL: 300000, // 5 mins
});

// https://majidlotfinia.medium.com/react-query-best-practices-separating-concerns-with-custom-hooks-3f1bc9051fa2

/**
 * useAsyncListAll
 * @param {String} operation GraphQL Operation
 * @param {Object} input Query params
 * @param {Object} options
 * @param {Object} useQueryParams
 * @return {Promise<Array[]>}
 */
export function useAsyncListAll(operation, input = {}, options = {}, useQueryParams = {}) {
  const queryKey = options.queryKey || [operation.match(/query ([a-zA-z]*)/)[1]];

  return useQuery({
    queryKey,
    queryFn: async () => asyncListAll(operation, input, options),
    ...useQueryParams,
  });
}

/**
 * asyncRetryMutation
 * @param {String} operation GraphQL Operation
 * @param {Object} input Query params
 * @param {Object} options
 * @param {Array} options.clearCacheKeys
 * @return {Promise<Object>}
 */
export function asyncRetryMutation(operation, input, options) {
  const retryOptions = Object.assign({
    retries: 3,
    factor: 3,
  }, options);

  return new Promise((resolve, reject) => {
    retry.operation(retryOptions).attempt(async () => {
      try {
        const result = await API.graphql(graphqlOperation(operation, input));

        if (options && options.clearCacheKeys) {
          // TODO: use global logger with log level
          console.log(`clear cache - ${options.clearCacheKeys.join(', ')}`);
          options.clearCacheKeys.forEach((key) => {
            modelCache.removeItem(key);
            listCache.removeItem(key);
          });
        }

        resolve(result);
      } catch (e) {
        console.error(e);
        reject(e.message || e);
      }
    });
  });
}

/**
 * mutationUnauth
 * @param {String} operation
 * @param {Object} input
 * @return {Promise<Object>}
 */
export function mutationUnauth(operation, input) {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const result = await API.graphql({
          query: operation,
          variables: input,
          authMode: 'API_KEY',
        });

        resolve(result);
      } catch (e) {
        reject(e.message || e);
      }
    })();
  });
}

/**
 * asyncGet
 * @param {String} operation GraphQL Operation
 * @param {Object} input Query params
 * @param {Object} options
 * @param {String} options.cacheKey
 * @param {Boolean} options.bypassCache
 * @return {Promise<Object>}
 **/
export async function asyncGet(operation, input, options = {}) {
  const { username = null } = input;
  const cacheKey = options.cacheKey || operation.match(/query ([a-zA-z]*)/)[1];

  if (USE_CACHE && !options.bypassCache) {
    const cache = modelCache.getItem(`${username}.${cacheKey}`);
    if (cache) {
      console.log(`cache hit - ${username}.${cacheKey}`);
      return cache;
    }
  }

  const result = await API.graphql(graphqlOperation(operation, input));

  if (USE_CACHE && options && !options.bypassCache) {
    console.log(`cache miss - ${username}.${cacheKey}`);
    modelCache.setItem(`${username}.${cacheKey}`, result);
  }

  return result;
}

/**
 * asyncListAll
 * @param {String} operation GraphQL Operation
 * @param {Object} input Query params
 * @param {Object} options
 * @param {Boolean} options.bypassCache
 * @param {String} options.cacheKey
 * @param {Array} allItems
 * @return {Promise<Array[]>}
 */
export async function asyncListAll(operation, input = {}, options = {}, allItems = []) {
  const res = await API.graphql(graphqlOperation(operation, {
    ...input,
    limit: 100,
  }));

  const { items, nextToken } = res.data[Object.keys(res.data)[0]];
  allItems = [...allItems, ...items];

  if (nextToken) {
    return asyncListAll(operation, { ...input, nextToken }, options, allItems);
  }

  return allItems;
}
