import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function NestedTableContainer({ columns = [], children, colSpanPadding = 1 }) {
  return (
    <TableRow>
      <TableCell colSpan={columns.length + colSpanPadding} classes={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
        {Array.isArray(children) ?
          children.map((x, index)=>(
            <div key={index} style={{ marginBottom: 16 }}>
              {x}
            </div>
          )):
          children}
      </TableCell>
    </TableRow>);
}

NestedTableContainer.propTypes = {
  columns: PropTypes.array,
  children: PropTypes.node,
  colSpanPadding: PropTypes.number,
};

export default NestedTableContainer;
