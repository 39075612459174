const getEnvironmentName = (cfg) => {
  if (!cfg) return 'unknown';
  if (!cfg.aws_cloud_logic_custom) return 'unknown';
  if (cfg.aws_cloud_logic_custom.length === 0) return 'unknown';
  return cfg.aws_cloud_logic_custom[0].endpoint.split('/').pop();
};

const getEnvOAuthConfig = (envName) => {
  const { origin } = window.location;

  return {
    domain: `pay-crm-${envName}.auth.us-east-1.amazoncognito.com`,
    scope: ['email', 'openid'],
    redirectSignIn: `${origin}/login/callback/`,
    redirectSignOut: `${origin}/logout/`,
    responseType: 'code', // token
  };
};

module.exports = {
  getEnvironmentName,
  getEnvOAuthConfig,
};
