import React from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import amplifyConfig from '../../aws-exports';
import appConfig from '../../../package.json';

const {
  NODE_ENV = 'development',
} = (process || {}).env;

const getEnvironmentName = (cfg) => {
  if (!cfg) return 'unknown';
  if (!cfg.aws_cloud_logic_custom) return 'unknown';
  if (cfg.aws_cloud_logic_custom.length === 0) return 'unknown';
  return cfg.aws_cloud_logic_custom[0].endpoint.split('/').pop();
};

export default function Footer() {
  const version = appConfig.version;
  const envName = getEnvironmentName(amplifyConfig);
  const isPrd = envName === 'production' || NODE_ENV !== 'development';
  const env = isPrd ? '' : envName;
  const info = `v${version}` + (env ? ` (${env})` : '');

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        p: 0,
        color: 'text.secondary',
      }}>
      <div>
        © Copyright {moment().format('YYYY')} <Link
          href="https://www.transurban.com/"
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Transurban Limited
        </Link>
        &nbsp;
        All rights reserved.
        &nbsp;|&nbsp;
        {info}
      </div>
    </Grid>
  );
}
