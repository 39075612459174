import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';

const ControlledInput = ({
  control,
  errors,
  type,
  name,
  label,
  autoFocus = false,
  required = false,
  disabled = false,
  multiline = false,
  rows = null,
  pattern = null,
  minLength = null,
  maxLength = null,
  options = null,
  placeholder = null,
  invalidText,
  setValue = null,
  register = null,
  shrinkLabel = null,
  size = null,
  accept = 'image/*',
  defaultValue = '',
  defaultChecked = null,
  inputProps = {},
  InputProps = {},
  renderOptions = {},
}) => {
  const hasError = typeof errors[name] !== 'undefined';
  if (
    type === 'text' ||
    type === 'password' ||
    type === 'number' ||
    type === 'date' ||
    type === 'email'
  ) {
    const additionalOptions = {};
    if (shrinkLabel) {
      additionalOptions['InputLabelProps'] = {
        shrink: true,
      };
    }

    if ((type === 'text' || type === 'email') && setValue) {
      inputProps.onBlur = async (e) => {
        setValue(name, e.target.value.trim());
      };
    }

    return (
      <Controller
        name={name}
        control={control}
        rules={buildRules()}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (<TextField
            id={name}
            name={name}
            label={label}
            type={type}
            required={required}
            multiline={multiline}
            rows={rows}
            size={size}
            placeholder={placeholder}
            fullWidth
            autoFocus={autoFocus}
            autoComplete={name}
            variant="outlined"
            error={hasError}
            helperText={hasError ? invalidText : null}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={InputProps}
            {...additionalOptions}
            {...field}
          />);
        }}
      />
    );
  }

  if (type === 'hidden') {
    return (
      <Input type="hidden" name={name} value={defaultValue} />
    );
  }

  if (type === 'select') {
    return (
      <FormControl
        sx={{ m: 1, minWidth: 400 }}
        variant="outlined"
        error={hasError}
        required={required}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          render={() => {
            return (
              <Select
                labelId={name}
                label={label}
                autoWidth
                value={defaultValue || 'select'}
                disabled={disabled}
                onChange={inputProps.onChange}
              >
                {options.map((option, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={option.value}
                      data-test-value={option.testValue || undefined}
                    >{option.label}</MenuItem>
                  );
                })}
              </Select>
            );
          }}
        />
        {hasError && invalidText && (
          <FormHelperText id={`${name}-helper-text`}>
            {invalidText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControl
        error={hasError}
        required={required}
      >
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value },
          }) => {
            if (value === '' && setValue) {
              setValue(name, defaultChecked);
            }
            return (
              /* eslint-disable */
              <FormControlLabel
                label={label}
                value={value}
                control={<Checkbox
                  id={name}
                  inputProps={inputProps}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  disabled={disabled}
                />}
              />
            )
          }}
        />
        {hasError && invalidText && (
          <FormHelperText id={`${name}-helper-text`}>
            {invalidText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  if (type === 'radio') {
    const {
      row = false,
      labelPlacement = 'end',
    } = renderOptions;
    return (
      <FormControl
        component="fieldset"
        error={hasError}
        required={required}
      >
        <FormLabel
          data-label={name}
          component="legend"
        >
          {label}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={buildRules()}
          defaultValue={defaultValue}
          render={() => {
            return (
              <RadioGroup
                id={name}
                required={required}
                name={name}
                defaultValue={defaultValue}
                row={row}
                fullWidth={row}
              >
                {options.map((option, index) => {
                  const { disabled } = option;
                  return (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      label={option.label}
                      labelPlacement={labelPlacement}
                      control={<Radio
                        onChange={inputProps.onChange}
                        disabled={disabled}
                        icon={(option.icon) ? option.icon : null}
                        checkedIcon={(option.checkedIcon) ? option.checkedIcon : null}
                        inputProps={{
                          'data-value': option.value,
                        }}
                      />}
                    />
                  );
                })}
              </RadioGroup>
            );
          }}
        />
      </FormControl>
    );
  }

  if (type === 'file') {
    return (
      <FormControl
        component="fieldset"
        error={hasError}
        required={required}
      >
        <FormLabel
          data-label={name}
          component="legend"
        >
          {label}
        </FormLabel>
        <input
          type="file"
          accept={accept}
          name={name}
          ref={register(buildRules())}
          onChange={inputProps.onChange}
        />
      </FormControl>
    );
  }

  function buildRules() {
    const rules = {};
    if (required) {
      rules.required = true;
    }

    if (pattern) {
      rules.pattern = pattern;
    }

    if (minLength) {
      rules.minLength = minLength;
    }

    if (maxLength) {
      rules.maxLength = maxLength;
    }

    return rules;
  }

  return (<div />);
};

ControlledInput.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  pattern: PropTypes.instanceOf(RegExp),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  invalidText: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  shrinkLabel: PropTypes.bool,
  size: PropTypes.string,
  accept: PropTypes.string,
  defaultValue: PropTypes.any,
  defaultChecked: PropTypes.bool,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  renderOptions: PropTypes.object,
};

export default ControlledInput;
