import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function CustomAppBar({ routes }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleToggleMenu() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleCloseMenu(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  async function handleSignOut(event) {
    handleCloseMenu(event);
    try {
      await Auth.signOut();
      navigate('/signin');
    } catch (e) {
      navigate('/signin');
    }
  }

  return (
    <AppBar position="absolute" color="primary">
      <Toolbar>
        {routes.filter((x) => !x.hideFromMenu).map((route) => (
          <Button key={route.path} color="navigation" component={Link} to={route.path}>
            <Typography component="p" color="inherit" noWrap>
              {route.title}
            </Typography>
          </Button>
        ))}
        <IconButton
          ref={anchorRef}
          color="inherit"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          onClick={handleToggleMenu}
          sx={{
            ml: 'auto',
          }}
        >
          <PersonIcon />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <MenuList id="user-menu" autoFocusItem={open}>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Toolbar>
    </AppBar >
  );
}

CustomAppBar.propTypes = {
  routes: PropTypes.array.isRequired,
};
