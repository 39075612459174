export const listAppHealths = /* GraphQL */ `
  query ListAppHealths(
    $id: ID
    $filter: ModelAppHealthFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppHealths(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        clientId
        key
        isActive
        status
        statusMessage
        statusUpdatedAt
        interval
        pingdomCheckId
        # appHealthHitory {
        #   items {
        #     # id
        #     # appHealthId
        #     status
        #     statusMessage
        #     responseTimestamp
        #     # expirationUnixTime
        #     createdAt
        #     # updatedAt
        #     # appHealthAppHealthHitoryId
        #   }
        #   nextToken
        # }
        postmanCollectionId
        postmanEnvironmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const appHealthHistoriesByAppHealthIdAndCreatedAt = /* GraphQL */ `
  query AppHealthHistoriesByAppHealthIdAndCreatedAt(
    $appHealthId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppHealthHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appHealthHistoriesByAppHealthIdAndCreatedAt(
      appHealthId: $appHealthId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        # appHealthId
        status
        statusMessage
        responseTimestamp
        expirationUnixTime
        # appHealth {
        #   id
        #   clientId
        #   key
        #   isActive
        #   status
        #   statusMessage
        #   statusUpdatedAt
        #   interval
        #   appHealthHitory {
        #     nextToken
        #   }
        #   postmanCollectionId
        #   postmanEnvironmentId
        #   createdAt
        #   updatedAt
        # }
        createdAt
        # updatedAt
        # appHealthAppHealthHitoryId
      }
      nextToken
    }
  }
`;

export const getAppHealthByClientIdByKey = /* GraphQL */ `
  query GetAppHealthByClientIdByKey(
    $clientId: ID!
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppHealthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAppHealthByClientIdByKey(
      clientId: $clientId
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        key
        isActive
        status
        statusMessage
        statusUpdatedAt
        interval
        pingdomCheckId
        # appHealthHitory {
        #   items {
        #     id
        #     appHealthId
        #     status
        #     statusMessage
        #     responseTimestamp
        #     expirationUnixTime
        #     createdAt
        #     updatedAt
        #     appHealthAppHealthHitoryId
        #   }
        #   nextToken
        # }
        postmanCollectionId
        postmanEnvironmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
