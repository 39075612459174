/* eslint-disable react-hooks/rules-of-hooks */
import {
  API,
} from 'aws-amplify';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const RESTAPI_NAME = 'paycrmapi';

export function useClientApi(action, options = {}) {
  const path = `/clients`;
  switch (action) {
  case 'list':
    return useQuery({
      queryKey: [path, action],
      queryFn: () => API.get(RESTAPI_NAME, path, {
        queryStringParameters: {
          action,
        },
      }),
      ...options,
    });
  case 'rollout':
    return useMutation({
      mutationFn: (body) => API.post(RESTAPI_NAME, path, {
        queryStringParameters: {
          action,
        },
        body,
      }),
      // onSuccess: (data) => {
      //   console.log(data);
      //   const message = "success"
      //   alert(message)
      // },
      // onError: () => {
      //   alert("there was an error")
      // },
      // onSettled: () => {
      //   queryClient.invalidateQueries('create');
      // }
    });
  case 'setPingdom':
    return useMutation({
      mutationFn: (body) => API.post(RESTAPI_NAME, path, {
        queryStringParameters: {
          action,
        },
        body,
      }),
    });

  default:
  }
}
