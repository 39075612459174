import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

const DataTable = ({
  title = 'Data',
  options: inOptions = {},
  data = [],
  columns = [],
}) => {
  const options = Object.assign({
    enableNestedDataAccess: '.',
    pagination: true,
    responsive: 'standard',
    rowsPerPageOptions: [10, 20, 100],
    rowsPerPage: 10,
    filterType: 'checkbox',
    fixedHeader: true,
    resizableColumns: false,
    selectableRows: 'none',
    print: true,
    download: true,
    downloadOptions: {
      filename: `${title}.csv`,
      separator: ',',
    },
    expandableRows: false,
    isRowExpandable: () => false,
    isRowSelectable: () => false,
    onRowClick: (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    },
  }, inOptions);

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  options: PropTypes.any,
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default DataTable;
