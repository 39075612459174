import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';

import './index.css';
import App from './apps/Console';
import Footer from './components/Footer';
import BaseTheme from './themes/base';
import { getEnvOAuthConfig, getEnvironmentName } from './oauthConfig';

import awsExports from './aws-exports';

const updatedAwsExports = Object.assign(awsExports, {
  oauth: getEnvOAuthConfig(getEnvironmentName(awsExports)) || {},
});
console.log(updatedAwsExports);
Amplify.configure(updatedAwsExports);

const queryClient = new QueryClient();

const theme = createTheme(BaseTheme);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <Footer />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
