const BaseTheme = {
  palette: {
    primary: {
      light: '#6c8f4c',
      main: '#57733d',
      dark: '#465d31',
      contrastText: '#fff',
    },
    secondary: {
      main: '#b2a399',
    },
    navigation: {
      main: '#ffffff',
    },
  },
  components: {
    MuiButton: {},
    MuiFormControl: {
      width: '100%',
    },
    MuiFormLabel: {},
    MuiPaper: {},
    MuiTypography: {
      styleOverrides: {
        h5: ({ theme }) => {
          return theme.unstable_sx({
            mb: 3,
          });
        },
      },
    },
  },
};

export default BaseTheme;
