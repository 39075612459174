import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import AppHealthTable from 'pages/AppHealthDashboard/AppHealthTable';
import AppHealthLineChart from 'pages/AppHealthDashboard/AppHealthLineChart';

import { useAsyncListAll } from 'utilities/graph';
import { getAppHealthByClientIdByKey } from 'pages/AppHealthDashboard/queries';

const ClientDetails = ({ clientId: inClientId }) => {
  const { clientId: urlParamsClientId } = useParams();

  const [clientId, setClientId] = useState();
  const { data, isLoading } = useAsyncListAll(getAppHealthByClientIdByKey, { clientId }, {
    bypassCache: true,
  }, {
    queryKey: ['getAppHealthByClientIdByKey', clientId],
    enabled: !!clientId,
  });

  useEffect(() => {
    if (inClientId) {
      setClientId(inClientId);
    } else
    if (urlParamsClientId) {
      setClientId(urlParamsClientId);
    }
  }, [inClientId, urlParamsClientId]);

  if (isLoading) return null;

  return (
    <Container maxWidth={false} style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ height: 360 }}>
          <AppHealthLineChart appHealthData={data} />
        </Grid>
        <Grid item xs={12}>
          <AppHealthTable clientId={clientId} appHealthData={data} />
        </Grid>
      </Grid>
    </Container>
  );
};

ClientDetails.propTypes = {
  clientId: PropTypes.string,
};

export default ClientDetails;
